import React from "react";
import PropTypes from "prop-types";
import { IoIosArrowForward } from "react-icons/io";
import { OurTeamBgImgOne, OurTeamBgImgTwo, OurTeamImg } from "../../../../images/index";
import Button from "../../../../components/Button";

const OurTeam = ({ content }) => {
  return (
    <section className=" relative w-full mx-auto">
      <img
        src={OurTeamBgImgOne}
        alt="Background Image 1"
        className="absolute right-0 top-0 xl:w-72 lg:w-36 md:w-24 w-12 -z-10"
      />
      <img
        src={OurTeamBgImgTwo}
        alt="Background Image 2"
        className="absolute left-0 bottom-0 xl:w-72 lg:w-36 md:w-24 w-12 -z-10"
      />
      <div className=" w-11/12 md:w-4/5 lg:w-8/12 xl:w-1/2 flex flex-col md:flex-row items-center mx-auto py-4 md:py-20">
        <img src={OurTeamImg} alt="Team Image" className="w-full md:w-1/2 px-4 mb-3" />
        <div className=" px-4">
          <div className=" mb-6">{content}</div>
          <Button
            text="meet our team"
            sideImg={<IoIosArrowForward />}
            link="/about/our-team/"
            style={{ backgroundColor: "#0064bf", color: "#fff" }}
          />
        </div>
      </div>
    </section>
  );
};
OurTeam.propTypes = {
  content: PropTypes.object.isRequired,
};

export default OurTeam;
