import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
function JoinTheTeam({ bgImg, heading, content, hrEmail }) {
  return (
    <div className="bg-[#0064bf] relative block sm:mt-8 md:mt-0">
      <GatsbyImage image={bgImg} className="check-it-out-img" />
      <div className="sm:absolute text-[25px] sm:block sm:text-[35px] top-[27%] sm:top-[4%] left-[9%] sm:w-[30%] md:w-[40%] lg:w-[35%] xl:w-[30%] xl:top-[27%] lg:top-[20%] md:top-[10%] unset py-[40px] md:py-[0px] mx-[auto] w-[90%] flex flex-col justify-center items-center">
        <div className="sm:text-[35px] text-25px] lg:text-[28px] sm:text-[16px] text-center sm:text-left font-extrabold leading-[1.2] text-[#ffffff] mb-[20px] 2xl:text-[48px] xl:text-4xl md:text-[25px]">
          {heading}
        </div>
        <div className="mb-1 md:mb-6 text-base text-[15px] text-center leading-5 sm:text-left text-[#ffffff] 2xl:text-[25px] md:text-[14px] lg:text-[16px] xl:text-lg">
          {content}
        </div>
        <div className="text-primary-yellow-dark font-extrabold text-sm lg:text-base">
          <a href="mailto:careers@kpisoft.com">{hrEmail}</a>
        </div>
      </div>
    </div>
  );
}
JoinTheTeam.propTypes = {
  bgImg: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.string,
  hrEmail: PropTypes.string,
};
export default JoinTheTeam;
