import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import Banner from "../../../containers/Banner";
import AboutThreeSecret from "../../../containers/global/about/why-entomo/aboutThreeSecret";
import OurTeam from "../../../containers/global/about/why-entomo/ourTeam";
import ExploreOurProducts from "../../../containers/global/about/why-entomo/exploreOurProducts";
import WhyEntomoSec from "../../../containers/global/about/why-entomo/whyEntomo";
import BreadCrumb from "../../../containers/BreadCrumb";
import Container from "../../../utils/Container";
import JoinTheTeam from "../../../containers/global/about/why-entomo/joinTheTeam";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "about",
    link: "/about",
  },
  {
    text: "why entomo",
    link: "",
  },
];
const WhyEntomo = ({ data }) => {
  const banner = data?.banner.nodes[0];
  const footerBanner = data?.footerBanner.nodes[0];
  return (
    <Layout>
      <Seo title="Why Entomo" />
      <Banner bgImg={banner.desktopImage.gatsbyImageData} mobImg={banner.mobileImage.gatsbyImageData}>
        {
          <div className="absolute top-16 lg:top-20 left-24 lg:left-24 xl:left-28 md:left-6 text-center md:text-left">
            <h2 className=" text-3xl lg:text-4xl xl:text-5xl 2xl:text-7xl text-center md:text-left font-extrabold">
              why <br />
              entomo ?
            </h2>
          </div>
        }
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 0" }} />
      </Container>
      <WhyEntomoSec
        content={
          <div className="text-[#4d4d4d] text-base my-6">
            <p>
              In an ever-evolving world of work, enterprises and business models are undergoing rapid and profound
              changes.Managing performance in the future of work will need more than legacy performance management
              systems.
              <br /> <br />
              <p className=" pb-4">
                <strong>entomo</strong> enables organizations and teams to be future-ready by offering solutions that
                ensure
              </p>
            </p>
            <ul className="list-disc pl-6  md:pl-10">
              <li>Everyday alignment of organizational, team and individual goals</li>
              <li>Personalized insights and nudges to drive power performance behaviors</li>
              <li>Engage and empower talent development and skills</li>
            </ul>
          </div>
        }
      />
      <AboutThreeSecret
        title="it’s not about surviving – it’s about thriving and flourishing."
        content={
          <>
            <p className="mb-4">
              The concept of <strong>entomo</strong> has evolved by taking inspiration from entomology – the study of
              insects.The modestly sized wonders of nature – survive, adapt &amp; evolve with their resilience,teamwork,
              and persistence.
            </p>
            <p className=" mb-4">
              <strong>‘Enterprises of Tomorrow’</strong> will benefit extraordinarily by embracing a similar philosophy.
            </p>
            <ul className="list-disc pl-6  md:pl-10">
              <li>Engage and align goals</li>
              <li>Empower with goal-oriented nudges</li>
              <li>Enable people growth</li>
            </ul>
          </>
        }
        lists={[
          "Become a purpose-led organization",
          "Apply intelligence across all processes and decisions",
          "Empower people to perform, learn and grow",
        ]}
      />
      <ExploreOurProducts
        ProductsTextBox={
          <p>
            entomo in <br /> action
          </p>
        }
        content={
          <p>
            <strong>entomo</strong> offers an end-to-end platform that enables{" "}
            <strong>‘Enterprises of Tomorrow’</strong> to perform, engage act and grow.
          </p>
        }
      />
      <OurTeam
        content={
          <>
            <p className=" text-3xl font-extrabold leading-5 pb-3">our team </p>
            <p>
              With expertise in technology and change management, our diverse teams are on a mission to revolutionize
              performance for the enterprises of tomorrow
            </p>
          </>
        }
      />
      <JoinTheTeam
        bgImg={footerBanner.desktopImage.gatsbyImageData}
        heading={
          <p>
            {" "}
            join the <br />
            entomo team
          </p>
        }
        content="We’re a team so obsessed with our respective crafts, we don’t really fit in anywhere else. Come join us"
        hrEmail=" Send your resumes to careers@entomo.co"
      />
    </Layout>
  );
};

WhyEntomo.propTypes = {
  data: PropTypes.object,
};

export default WhyEntomo;

export const query = graphql`
  query {
    banner: allContentfulBannerContent(filter: { heading: { eq: "Why Entomo Page Banner" } }) {
      nodes {
        desktopImage {
          gatsbyImageData
        }
        mobileImage {
          gatsbyImageData
        }
      }
    }
    footerBanner: allContentfulBannerContent(filter: { heading: { eq: "Why Entomo Page Footer Banner" } }) {
      nodes {
        title
        subTitle
        desktopImage {
          gatsbyImageData
        }
      }
    }
  }
`;
