import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import PropTypes from "prop-types";
import { YellowBorders } from "../../../../images/index";
import Button from "../../../../components/Button";
import { ExploreOurProductsBg } from "../../../../images/index";

const ExploreOurProducts = ({ ProductsTextBox, content }) => {
  return (
    <section
      className="h-[31rem] md:h-[19rem] lg:h-[22.5rem] xl:h-[26.5rem] 2xl:h-[40rem] relative text-white bg-cover w-full mx-auto"
      style={{ backgroundImage: `url('${ExploreOurProductsBg}')` }}
    >
      <div className="2xl:w-2/4 xl:w-4/6 md:w-10/12 mx-auto top-1/4 flex flex-col md:flex-row items-center justify-center h-[90%]">
        <div className="w-2/3 lg:w-3/5  px-4 relative">
          <img src={YellowBorders} alt="YellowBorder" className="relative" />
          <div className=" absolute top-[30%] left-[15%] w-fit xl:text-3xl lg:text-2xl">{ProductsTextBox}</div>
        </div>
        <div className=" px-12">
          <p className=" my-6">{content}</p>
          <Button
            text="explore our products"
            sideImg={<IoIosArrowForward />}
            link="/solutions/"
            style={{ backgroundColor: "#ffda00", color: "#404040" }}
          />
        </div>
      </div>
    </section>
  );
};
ExploreOurProducts.propTypes = {
  ProductsTextBox: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};

export default ExploreOurProducts;
