import React from "react";
import PropTypes from "prop-types";
import Container from "../../../../utils/Container";
import { Butterfly } from "../../../../images/index";

const AboutThreeSecret = ({ title, content, lists }) => {
  return (
    <Container>
      <div className="lg:flex text-[#4d4d4d] w-full">
        <div className="hidden sm:block pr-8">
          <img src={Butterfly} alt="Butterfly Image" className=" rounded-xl" />
        </div>
        <div>
          <div>
            <p className=" pb-8 lg:pb-4 md:pt-8 lg:pt-0 text-primary-blue-dark text-xl lg:text-2xl xl:text-3xl font-extrabold">
              {title}
            </p>
            <img src={Butterfly} alt="Butterfly Image" className="block sm:hidden " />
          </div>
          <div> {content}</div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 m-3 justify-between   w-full text-[#808080] font-extrabold">
        {lists.map((list) => {
          return (
            <div key={list} className="md:flex md:justify-center my-2">
              <p className="md:block hidden bg-primary-yellow-dark w-3 h-10 rounded-xl mr-2 my-2"></p>
              <p className="xl:text-xl md:text-base text-sm lg:w-5/6">{list}</p>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
AboutThreeSecret.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  lists: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default AboutThreeSecret;
