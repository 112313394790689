import React from "react";
import PropTypes from "prop-types";
import Container from "../../../../utils/Container";

const WhyEntomo = ({ content }) => {
  return (
    <Container>
      <section className="flex flex-col-reverse lg:flex-row w-full  mx-auto text-sm md:text-base 2xl:text-lg">
        <div className="lg:w-1/2 pr-5 lg:mb-4 ">
          <div className="hidden lg:block text-lg">
            <h2 className="text-primary-blue-dark text-xl lg:text-2xl xl:text-3xl font-extrabold">
              Purpose | Insights | Growth
            </h2>
          </div>
          {content}
        </div>
        <div className="lg:w-2/3 h-52 md:h-96 lg:h-72 2xl:h-[350px] mb-6">
          <div className="block lg:hidden text-lg text-primary-blue-dark font-extrabold">
            <h2>Purpose | Insights | Growth </h2>
          </div>
          <iframe
            src="https://www.youtube.com/embed/Ot_C794fZ3A"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className=" rounded-xl w-full lg:w-11/12 xl:w-2/3 h-full mx-0 lg:mx-auto mt-2"
          ></iframe>
        </div>
      </section>
    </Container>
  );
};
WhyEntomo.propTypes = {
  content: PropTypes.object.isRequired,
};

export default WhyEntomo;
